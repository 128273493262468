import React from 'react';
import './w3.css'; 
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@mui/material';

const display = {
    display: 'block'
};
const hide = {
    display: 'none'
};

class AvisoError extends React.Component {
    render() {
        let showOrNot = (this.props.show)?display:hide;
        return (
            <div className='w3-modal' style={showOrNot}>
                <div className='w3-modal-content w3-animate-zoom w3-card-4'>
                    <div className='w3-container w3-red'>
                        <h4><FontAwesomeIcon icon={faExclamationTriangle} style={{ color: 'yellow', marginRight: 12 }} />¡Error!</h4>
                    </div>
                    <div className='w3-container' style={{ color: '#707070'}}>
                        <p></p>
                        <h5>{this.props.mensaje}</h5>
                        <h5>Por favor, intente nuevamente.</h5>
                        <p></p>
                    </div>
                    <div className='w3-bar w3-center w3-container'>
                        <Button size='small' variant='contained' onClick={this.props.cierreAviso} color='primary'>Cerrar notificación</Button>
                        <p style={{ margin: 0 }}>&nbsp;</p>
                    </div>
                </div>
            </div>
        );
    }
}

export default AvisoError;