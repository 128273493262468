import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Tablero from './components/Tablero';
import Registrar from './components/Registrar';

class App extends React.Component {
    constructor(props) {
        super(props);
        this.subscripcion = this.subscripcion.bind(this);
        this.initialData = this.initialData.bind(this);
    }

    subscripcion(numero) {
        const eventSource = new EventSource('api/conexion/' + numero);
        this.setState({ numJugador: numero, eventSource: eventSource });
    }

    initialData(data) {
        this.setState({ initialData: data});
    }

    render() {
        let jugador = (this.state !== null) ? this.state.numJugador : -1;
        let initialData = (this.state !== null) ? this.state.initialData : null;
        let eSource = (this.state !== null) ? this.state.eventSource : null;
        return (
            <BrowserRouter>
                <Routes>
                    <Route 
                        path="/" 
                        element={
                            <Registrar pSubscripcion={this.subscripcion} pInitialData={this.initialData} eventSource={eSource} />
                        } 
                        exact 
                    />
                    <Route 
                        path="/partida" 
                        element={
                            <Tablero ego={jugador} initialData={initialData} eventSource={eSource} />
                        } 
                    />
                </Routes>
            </BrowserRouter>
        )
    }
}

export default App;