import React from 'react';
import '../index.css';
import Carta from './Carta';

class Mano extends React.Component {

    render() {
        const { cartas } = this.props;
        return (
            <div className='contenedor' style={{ borderTop: 0, minHeight: 86 }}>
                <p style={{ marginTop: 0, marginBottom: 8 }}>En mano</p>
                <div className='mano'>
                    {(cartas.length !== 0) ? cartas.map((c, i) => {
                        return (
                            <div className='unaCarta' key={i} draggable={c >- 1}
                                onDragStart={(e) => { this.props.pDragStart(e, i) }}
                                onDragEnter={(e) => { this.props.pDragEnter(e, 'mano', i) }}
                                onDragEnd={(e) => { this.props.pDragEnd(e) }}
                                onDragOver={(e) => { this.props.pDragTarget(e) }}
                                onDrop={(e) => { this.props.pDragTarget(e) }}
                            >
                                <Carta codigo={c} />
                            </div>
                        )
                    }) : ''}
                </div>
            </div>
        );
    }
}

export default Mano;