import React from 'react';
import Carta from './Carta';
import { Button } from '@mui/material';
import AvisoError from './AvisoError';
import '../index.css';

class Plantilla extends React.Component {
    constructor (props) {
        super (props);
        this.state = { mostrarError: false };
        this.bajar = this.bajar.bind(this);
        this.prenderBajar = this.prenderBajar.bind(this);
        this.prenderCancelar = this.prenderCancelar.bind(this);
        this.cancelarNotificacion = this.cancelarNotificacion.bind(this);
    }

    bajar() {
        if (!this.validarBajar()) {
            this.setState({ mostrarError: true })
        } else {
            this.props.pBajar();
        }
    }

    validarBajar() {
        let valido = true;
        let indice = -1;
        while (valido && indice < this.props.esquema.length) {
            // eslint-disable-next-line
            let proxIndice = this.props.esquema.findIndex((c, i) => (c === -2) && (i > indice));
            if (proxIndice === -1) proxIndice = this.props.esquema.length;
            if (this.props.esquema[proxIndice - 1] !== -1) {
                // eslint-disable-next-line
                valido = ((proxIndice - indice - 1) === 3) ? this.validarPierna(this.props.esquema.filter((c, i) => i > indice && i < proxIndice)) :
                    // eslint-disable-next-line
                    this.validarEscalera(this.props.esquema.filter((c, i) => i > indice && i < proxIndice));
            }
            indice = proxIndice;
        }
        return valido;
    }

    validarPierna(cartas) {
        let resultado = false;
        let valores = Array.from(cartas,c => this.getValor(c));
        let palos = Array.from(cartas, c => this.getPalo(c));
        let cual = palos.findIndex(p => p !== 4);
        if (cual > -1) {
            let cuenta = valores.filter(v => v === valores[cual]).length;
            if (cuenta === 3 || (cuenta === 2 && palos.filter(p => p === 4).length === 1)) {
                resultado = true;
            }
        }
        return resultado;
    }

    validarEscalera(cartas) {
        let resultado = false;
        let valores = Array.from(cartas,c => this.getValor(c));
        let palos = Array.from(cartas, c => this.getPalo(c));
        let qComodines = palos.filter(p => p === 4).length;
        let pCarta = palos.findIndex(p => p !== 4);
        if (qComodines < 2) {
            let cuenta = palos.filter(p => p === palos[pCarta]).length;
            if (cuenta === 4 || (cuenta === 3 && qComodines === 1 && !(palos[0] === 4 && valores[1] === 0))) {
                let inicio = valores[pCarta++];
                while (pCarta < valores.length && (valores[pCarta] === inicio + 1 || palos[pCarta] === 4 || (valores[pCarta] === 0 && inicio === 12))) {
                    inicio++;
                    pCarta++;
                }
                if (pCarta === valores.length && !(palos[palos.length-1] === 4 && valores[valores.length-2] === 0)) {
                    resultado = true;
                }
            }
        }
        return resultado;
    }

    getValor(codigo) {
        let subMazo = Math.floor(codigo/54);
        let indicePalo = Math.floor((codigo-subMazo*54)/13);
        return codigo-subMazo*54-indicePalo*13;
    }

    getPalo(codigo) {
        //4 es comodín
        let subMazo = Math.floor(codigo/54);
        return Math.floor((codigo-subMazo*54)/13);
    }

    cancelarNotificacion() {
        this.props.pCancelar();
        this.setState({ mostrarError: false });
    }

    prenderBajar() {
        let respuesta = false;
        if (!this.props.bajo && this.props.esquema.filter(c => c === -1).length === 0) {
            respuesta = true;
        } else {
            if (this.props.bajo) {
                let inicio = -1;
                let fin = this.props.esquema.findIndex((c, i) => (c === -2) && i > inicio);
                let fayuto = false;
                while (!fayuto && inicio < this.props.esquema.length) {
                    // eslint-disable-next-line
                    if ((this.props.esquema.filter((c, i) => (c !== -1) && (i > inicio) && (i < fin)).length === (fin - inicio - 1)) ||
                    // eslint-disable-next-line
                        (this.props.esquema.filter((c, i) => (c === -1) && (i > inicio) && (i < fin)).length === (fin - inicio - 1))) {
                        inicio = fin;
                        // eslint-disable-next-line
                        fin = this.props.esquema.findIndex((c, i) => (c === -2) && i > inicio);
                        if (fin === -1) fin = this.props.esquema.length;
                    } else {
                        fayuto = true;
                    }
                }
                if (!fayuto && this.props.esquema.filter(c => c >= 0).length > 0) {
                    respuesta = true;
                }
            }
        }
        return respuesta;     
    }

    prenderCancelar() {
        return (this.props.esquema.filter(c => c >= 0).length > 0);
    }
    
    render () {
        const bajarOn = this.prenderBajar();
        const cancelarOn = this.prenderCancelar();        
        return (
            <div className='contenedor mano' style={{ borderTop: 0 }}>
                <AvisoError show={this.state.mostrarError} mensaje='Las cartas que intenta bajar no forman piernas o escaleras válidas.' cierreAviso={this.cancelarNotificacion} />
                <div className='mano'>
                    {this.props.esquema.map((c, i) => {
                        if (c === -1) {
                            return (
                                <div className='unaCarta' key={i} draggable='false'
                                    onDragEnter={(e) => { this.props.pDragEnter(e, 'plantilla', i) }}
                                    onDragOver={(e) => { this.props.pDragTarget(e) }}
                                    onDrop={(e) => { this.props.pDragTarget(e) }}
                                >
                                    <Carta codigo={c} />
                                </div>
                            )
                        } else {
                            return (
                                <div className='unaCarta' key={i} draggable='false'>
                                    <Carta codigo={c} />
                                </div>
                            )
                        }
                    })
                    }
                </div>
                <div className='mano botones'>
                    <Button size='small' variant='contained' onClick={this.props.pCancelar} disabled={!cancelarOn}>Cancelar</Button>
                    <Button size='small' variant='contained' onClick={this.bajar} color='primary' disabled={!bajarOn}>Bajar</Button>
                </div>
            </div >
        );
    }
}

export default Plantilla;