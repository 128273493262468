import React from 'react';
import { withRouter } from './withRouter';
import Mano from './Mano';
import Plantilla from './Plantilla';
import Jugador from './Jugador';
import Mesa from './Mesa';
import AvisoError from './AvisoError';
import AvisoCorto from './AvisoCorto';
import Cabecera from './Cabecera';
import FinPartida from './FinPartida';
import AvisoSalvar from './AvisoSalvar';

class Tablero extends React.Component {
	constructor (props) {
		super(props);
		this.plantilla = React.createRef();
		this.continuar = React.createRef();
		this.dragStart = this.dragStart.bind(this);
		this.dragEnter = this.dragEnter.bind(this);
		this.dragEnd = this.dragEnd.bind(this);
		this.dragTarget = this.dragTarget.bind(this);
		this.cancelarPlantilla = this.cancelarPlantilla.bind(this);
		this.cancelarNotificacion = this.cancelarNotificacion.bind(this);
		this.bajar = this.bajar.bind(this);
		this.puedeMover = this.puedeMover.bind(this);
		this.cartaClick = this.cartaClick.bind(this);
		this.reclamarPozo = this.reclamarPozo.bind(this);
		this.loadEstado = this.loadEstado.bind(this);
		this.handleEstado = this.handleEstado.bind(this);
		this.handleLevanto = this.handleLevanto.bind(this);
		this.handleRoboPozo = this.handleRoboPozo.bind(this);
		this.handleReclamoPozo = this.handleReclamoPozo.bind(this);
		this.prenderReclamar = this.prenderReclamar.bind(this);
		this.handleDescarto = this.handleDescarto.bind(this);
		this.handleBajo = this.handleBajo.bind(this);
		this.handleCorto = this.handleCorto.bind(this);
		this.handleAbandono = this.handleAbandono.bind(this);
		this.handleFin = this.handleFin.bind(this);
		this.handleReset = this.handleReset.bind(this);
		this.handleSalvar = this.handleSalvar.bind(this);
		this.panicoClick = this.panicoClick.bind(this);
		this.salvar = this.salvar.bind(this);
	}

	componentDidMount() {
		this.props.eventSource.addEventListener('estado', this.handleEstado);
		this.props.eventSource.addEventListener('levanto', this.handleLevanto);
		this.props.eventSource.addEventListener('roboPozo', this.handleRoboPozo);
		this.props.eventSource.addEventListener('reclamo', this.handleReclamoPozo);
		this.props.eventSource.addEventListener('descarto', this.handleDescarto);
		this.props.eventSource.addEventListener('bajo', this.handleBajo);
		this.props.eventSource.addEventListener('corto', this.handleCorto);
		this.props.eventSource.addEventListener('abandono', this.handleAbandono);
		this.props.eventSource.addEventListener('fin', this.handleFin);
		this.props.eventSource.addEventListener('reset', this.handleReset);
		this.props.eventSource.addEventListener('salvar', this.handleSalvar);
		this.setState({ ego: this.props.ego });
		if (this.props.initialData !== null) this.loadEstado(this.props.initialData);
	}

	loadEstado(dataDelServidor) {
		let cartasEnMano = Array.from({ length: dataDelServidor.enMano.length }, () => 0);
		dataDelServidor.enMano.forEach((mano, i) => { cartasEnMano[i] = mano.length });
		let ego = (this.state !== null) ? this.state.ego : this.props.ego;
		let levanto = (dataDelServidor.turno === ego && dataDelServidor.jugador[dataDelServidor.turno].accion.startsWith('LEVANTÓ'));
		if (this.continuar.current !== null) this.continuar.current.prenderBoton();
		this.setState({
			jugador: dataDelServidor.jugador,
			enMano: dataDelServidor.enMano[ego],
			enMesa: dataDelServidor.enMesa,
			cartasEnMano: cartasEnMano,
			queMano: dataDelServidor.queMano,
			piernas: dataDelServidor.piernas,
			escaleras: dataDelServidor.escaleras,
			turno: dataDelServidor.turno,
			esquema: dataDelServidor.esquema,
			era: dataDelServidor.enMano[ego],
			pozo: dataDelServidor.pozo,
			mazo: dataDelServidor.mazo,
			anteriorPozo: dataDelServidor.anteriorPozo,
			ultimaDelMazo: dataDelServidor.ultimaDelMazo,
			mostrarMazo: -3,
			mostrarError: false,
			mostrarCorto: false,
			mostrarFin: false,
			mostrarSalvar: false,
			mensaje: '',
			recienBajo: false,
			levanto: levanto,
			reclamarOn: false
		});
	}

	handleEstado(event) {
		const data = JSON.parse(event.data);
		this.loadEstado(data);
	}

	handleLevanto(event) {
		const data = JSON.parse(event.data);
		let whichOne = data.accion.substr(data.accion.length-4);
		if (this.state.turno !== this.state.ego) {
			let copiedCartasEnMano = this.state.cartasEnMano.slice();
			copiedCartasEnMano[this.state.turno]++;
			let tempJugador = JSON.parse(JSON.stringify(this.state.jugador));
			tempJugador[this.state.turno].accion = data.accion;
			let pozo = (whichOne === 'POZO') ? this.state.anteriorPozo : this.state.pozo;
			let mostrarMazo = (whichOne === 'MAZO' && this.state.ultimaDelMazo) ? -1 : -3;
			this.setState({ jugador: tempJugador, pozo: pozo, mostrarMazo: mostrarMazo, cartasEnMano: copiedCartasEnMano });
		}
		if (whichOne === 'MAZO') {
			this.setState({ mazo: data.mazo, ultimaDelMazo: data.ultimaDelMazo });
		}
	}

	handleRoboPozo(event) {
		const quien = JSON.parse(event.data);
		if (quien !== this.state.ego) {
			let copiedCartasEnMano = this.state.cartasEnMano.slice();
			copiedCartasEnMano[quien]++;
			let tempJugador = JSON.parse(JSON.stringify(this.state.jugador));
			tempJugador[quien].accion = 'LEVANTÓ DEL POZO';
			let pozo = this.state.anteriorPozo;
			let reclamarOn = this.prenderReclamar(quien);
			this.setState({ jugador: tempJugador, pozo: pozo, cartasEnMano: copiedCartasEnMano, reclamarOn: reclamarOn });
		}
	}

	handleReclamoPozo(event) {
		const data = JSON.parse(event.data);
		let tempJugador = JSON.parse(JSON.stringify(this.state.jugador));
		tempJugador[data.deQuien].accion='';
		tempJugador[data.aQuien].accion = 'LEVANTÓ DEL POZO';
		let copiedCartasEnMano = this.state.cartasEnMano.slice();
		copiedCartasEnMano[data.deQuien]--;
		copiedCartasEnMano[data.aQuien]++;
		let enMano = this.state.enMano.slice();
		let reclamarOn = false;
		if (this.state.ego === data.deQuien) {
			enMano = enMano.filter(c => c !== data.carta);
		} else {
			if (this.state.ego === data.aQuien) {
				enMano.push(data.carta);
			} else {
				reclamarOn = this.prenderReclamar(data.aQuien);
			}
		}
		this.setState({ 
			jugador: tempJugador, cartasEnMano: copiedCartasEnMano, enMano: enMano, era: enMano, reclamarOn: reclamarOn
		 });
	}

	prenderReclamar(target) {
		let miPrioridad = (this.state.ego - this.state.turno) >= 0 ? this.state.ego - this.state.turno : this.state.ego - this.state.turno + this.state.jugador.length;
		let targetPrioridad = (target - this.state.turno) >= 0 ? target - this.state.turno : target - this.state.turno + this.state.jugador.length;
		return (miPrioridad < targetPrioridad) 
			&& (this.state.enMesa[this.state.ego].length === 0) 
			&& (this.state.ego !== this.state.turno);
	}

	handleDescarto(event) {
		const data = JSON.parse(event.data);
		if (this.state.turno !== this.state.ego) {
			let copiedCartasEnMano = this.state.cartasEnMano.slice();
			copiedCartasEnMano[this.state.turno]--;
			let tempJugador = JSON.parse(JSON.stringify(this.state.jugador));
			tempJugador.forEach(jug => jug.accion = '');
			this.setState({ cartasEnMano: copiedCartasEnMano, pozo: data.carta, anteriorPozo: this.state.pozo, jugador: tempJugador, reclamarOn: false });
		}
		let turno = (this.state.turno + 1) % this.state.jugador.length;
		if (this.state.ultimaDelMazo) {
			setTimeout(() => {
				this.setState({ turno: turno, pozo: data.pozo, mazo: data.mazo, anteriorPozo: data.anteriorPozo, ultimaDelMazo: data.ultimaDelMazo });
			}, 2000);
		} else {
			this.setState({ turno: turno });
		}
	}

	handleBajo(event) {
		const data = JSON.parse(event.data);
		if (this.state.turno !== this.state.ego) {
			let copiedEnMesa = this.state.enMesa.slice().map((row) => { return row.slice(); });
			copiedEnMesa[data.indice] = copiedEnMesa[data.indice].splice.apply(data.cartas,[0,data.cartas.length]);
			let copiedCartasEnMano = this.state.cartasEnMano.slice();
			copiedCartasEnMano[this.state.turno] = data.enMano.length;
			this.setState({ enMesa: copiedEnMesa, cartasEnMano: copiedCartasEnMano });	
		}
	}

	handleCorto(event) {
		const data = JSON.parse(event.data);
		this.setState({
			jugador: data.jugador,
			enManoAll: data.enMano,
			puntosEnMano: data.puntosEnMano,
			pozo: data.carta,
			mostrarCorto: true
		})
	}

	handleAbandono(event) {
		const data = JSON.parse(event.data);
		if (data < this.state.ego) {
			this.setState({ ego: this.state.ego - 1 });
		} else {
			if (data === this.state.ego) {
				this.handleReset();
			}
		}
	}

	handleFin(event) {
		const jugadores = JSON.parse(event.data);
		this.setState({ jugador: jugadores, mostrarCorto: false, mostrarFin: true });
	}

	handleReset() {
		this.props.eventSource.removeEventListener('reset', this.handleReset);
		this.props.eventSource.close();
		this.props.navigate('/');
	}

	handleSalvar(event) {
		const partidaID = JSON.parse(event.data);
		this.setState({ partidaID: partidaID, mostrarSalvar: true });
	}

	dragStart(e, index) {
		e.stopPropagation();
		e.dataTransfer.setData('text', e.target.id);
		setTimeout(() => {
			let viejasCartas = this.state.enMano.slice();
			viejasCartas[index] = -1;
			this.setState({ dragValor: this.state.enMano[index], enMano: viejasCartas, dragIndex: index, dragWhere: 'mano' });
		}, 0);
	}

	dragEnter(e, where, index) {
		e.preventDefault();
		e.stopPropagation();
		switch (where) {
			case 'mano' :
				if (index !== this.state.dragIndex) {
					let viejasCartas = this.state.enMano.slice();
					viejasCartas.splice(index, 0, viejasCartas.splice(this.state.dragIndex, 1)[0]);
					let nuevoEra = this.state.era.slice();
					nuevoEra.splice(index, 0, nuevoEra.splice(this.state.dragIndex, 1)[0]);
					this.setState({ enMano: viejasCartas, dragIndex: index, dragWhere: where, era: nuevoEra });
				}
				break;
			case 'plantilla':
				if (this.state.esquema[index] === -1) {
					this.setState({ dragTargetIndex: index, dragWhere: where });
				}
				break;
			case 'pozo':
				this.setState({ dragTargetIndex: index, dragWhere: where });
				break;
			default:
				let jugador = parseInt(where.substring(where.length - 1));
				if (this.state.enMesa[jugador][index] === -1) {
					this.setState({ dragTargetIndex: index, dragWhere: where });
				}
				break;
		}
	}

	puedeMover() {
		let respuesta = false;
		let mensaje = '';
		if (this.state.turno === this.state.ego) {
			if (this.state.levanto) {
				if (!this.state.recienBajo) {
					if (this.state.enMano.length > 1) {
						respuesta = true;
					} else {
						mensaje = 'No puede bajar su última carta; debe cortar.';
					}
				} else {
					mensaje = 'En esta mano ya no puede bajar más cartas; ahora sólo puede descartar.';
				}
			} else {
				mensaje = 'Debe levantar antes de bajar cartas.';
			}
		} else {
			mensaje = 'No es su turno; espere a que le toque jugar.';
		}
		if (mensaje !== '') this.setState({ mostrarError: true, mensaje: mensaje });
		return respuesta;
	}

	dragEnd(e) {
		e.preventDefault();
		e.stopPropagation();
		switch (this.state.dragWhere) {
			case 'mano' :
				let viejasCartas = this.state.enMano.slice();
				viejasCartas[this.state.dragIndex] = this.state.dragValor;
				this.setState({ enMano: viejasCartas, dragValor: -1, dragIndex: -1, dragWhere: '', dragTargetIndex: -1 });
				break;
			case 'plantilla':
				if (this.puedeMover()) {
					let nuevoEsquema = this.state.esquema.slice();
					nuevoEsquema[this.state.dragTargetIndex] = this.state.dragValor;
					this.setState({ esquema: nuevoEsquema, dragValor: -1, dragIndex: -1, dragWhere: '', dragTargetIndex: -1 });
				}
				break;
			case 'pozo':
				if (this.state.levanto) {
					if (this.state.esquema.filter(c => c > -1).length === 0) {
						let mano = this.state.enMano.slice();
						mano = mano.filter(c => c !== -1);
						let copiedCartasEnMano = this.state.cartasEnMano.slice();
						copiedCartasEnMano[this.state.ego] = mano.length;
						let tempJugador = JSON.parse(JSON.stringify(this.state.jugador));
						tempJugador.forEach(jug => jug.accion = '');
						const requestOptions = {
							method: 'POST',
							headers: { 'Content-Type': 'application/json' },
							body: JSON.stringify(
								{
									descarto: this.state.dragValor,
									enMano: mano
								}
							)
						};
						this.setState({
							enMano: mano, era: mano, cartasEnMano: copiedCartasEnMano, pozo: this.state.dragValor, anteriorPozo: this.state.pozo,
							dragValor: -1, dragIndex: -1, dragWhere: '', dragTargetIndex: -1, levanto: false, recienBajo: false, 
							jugador: tempJugador
						});
						fetch('api/descarto', requestOptions);
					} else {
						this.setState({ mostrarError: true, mensaje: 'Aún tiene cartas sin bajar; oprima "Cancelar" o "Bajar" antes de descartar.' });
					}
				} else {
					this.setState({ mostrarError: true, mensaje: 'No puede descartar antes de haber levantado.' });
				}
				break;
			default:
				if (this.puedeMover()) {
					let jugador = parseInt(this.state.dragWhere.substring(this.state.dragWhere.length - 1));
					let inicio = (this.state.dragTargetIndex === 0 || this.state.enMesa[jugador][this.state.dragTargetIndex - 1] === -2) ? this.state.dragTargetIndex :
						this.state.enMesa[jugador].slice(0,this.state.dragTargetIndex).lastIndexOf(-2) + 1;
					let fin = (this.state.dragTargetIndex === this.state.enMesa[jugador].length - 1 || this.state.enMesa[jugador][this.state.dragTargetIndex + 1] === -2) ? this.state.dragTargetIndex :
						this.state.enMesa[jugador].findIndex((c, i) => c === -2 && i > this.state.dragTargetIndex) - 1;
					if (fin < 0) fin = this.state.enMesa[jugador].length - 1;
					let juego = this.state.enMesa[jugador].slice(inicio + 1, fin);
					if (this.state.enMesa[this.state.ego].length > 0 && this.validarDrop(juego, this.state.dragValor, this.state.dragTargetIndex - inicio - 1)) {
						let copiedEnMesa = this.state.enMesa.slice().map((row) => { return row.slice(); });
						copiedEnMesa[jugador][this.state.dragTargetIndex] = this.state.dragValor;
						if (inicio === this.state.dragTargetIndex) {
							copiedEnMesa[jugador].splice(this.state.dragTargetIndex, 0, -1);
						} else {
							copiedEnMesa[jugador].splice(this.state.dragTargetIndex + 1, 0, -1);
						}
						let mano = this.state.enMano.slice();
						mano = mano.filter(c => c !== -1);
						let copiedCartasEnMano = this.state.cartasEnMano.slice();
						copiedCartasEnMano[this.state.ego] = mano.length;
						let bajo = {
							indice: jugador,
							cartas: copiedEnMesa[jugador],
							enMano: mano
						};
						this.setState({
							enMesa: copiedEnMesa, enMano: mano, era: mano, cartasEnMano: copiedCartasEnMano,
							dragValor: -1, dragIndex: -1, dragWhere: '', dragTargetIndex: -1
						});
						const requestOptions = {
							method: 'POST',
							headers: { 'Content-Type': 'application/json' },
							body: JSON.stringify(bajo)
						};
						fetch('api/bajo', requestOptions);
					} else {
						let mensaje = (this.state.enMesa[this.state.ego].length > 0) ?
							'La carta que intenta bajar no forma un juego válido.' :
							'Debe bajar primero para poder agregar cartas en otros juegos.';
						this.setState({ mostrarError: true, mensaje: mensaje });
					}
				}
				break;
		}
	}

	cancelarNotificacion() {
		if (this.state.dragWhere !== 'pozo') {
			this.cancelarPlantilla();
		} else {
			let index = this.state.era.findIndex(c => c === this.state.dragValor);
			let mano = this.state.enMano.slice();
			mano[index] = this.state.dragValor;
			this.setState({ enMano: mano });
		}
        this.setState({ mostrarError: false });
    }

	dragTarget(e) {
		e.preventDefault();
		e.stopPropagation();
	}

	cancelarPlantilla() {
		let nuevoEsquema = this.state.esquema.slice();
		nuevoEsquema = nuevoEsquema.map(c => {return((c > -1)?-1:c)});
		this.setState({ enMano: this.state.era, esquema: nuevoEsquema });
	}

	validarDrop(juego,carta,indice) {
		let resultado = false;
		let dir = (indice === -1) ? 1 : -1;
		let cValor = this.plantilla.current.getValor(carta);
		let cPalo = this.plantilla.current.getPalo(carta);
		if (cPalo === 4) {
			if (this.plantilla.current.getPalo(juego[indice+dir]) !== 4) {
				if (this.plantilla.current.getValor(juego[indice+dir]) === 0) {
					if (dir > 0) {
						if (this.plantilla.current.getValor(juego[indice+dir+1]) !== 1 && this.plantilla.current.getValor(juego[indice+dir+2]) !== 2) {
							resultado = true;
						}
					} else {
						if (this.plantilla.current.getValor(juego[indice+dir-1]) !== 12 && this.plantilla.current.getValor(juego[indice+dir-2]) !== 11) {
							resultado = true;
						}
					}
				} else {
					resultado = true;
				}
			}
		} else {
			if (juego.filter(c => this.plantilla.current.getValor(c) === cValor && this.plantilla.current.getPalo(c) !== 4).length > 1) {
				resultado = true;
			} else {
				while (this.plantilla.current.getPalo(juego[indice + dir]) === 4) {
					dir += dir;
				}
				if (juego.filter(c => this.plantilla.current.getValor(c) === this.plantilla.current.getValor(juego[indice + dir])
					&& this.plantilla.current.getPalo(c) !== 4).length === 1) {
					let target = this.plantilla.current.getValor(juego[indice + dir]) - dir;
					if (target === 13) target = 0;
					if (target === 1 && indice > 3) target = 14;
					if (this.plantilla.current.getPalo(juego[indice + dir]) === cPalo && cValor === target) {
						resultado = true;
					}
				}
			}
		}
		return resultado;
	}

	bajar() {
		if (this.state.enMano.filter(c => c !== -1).length > 0) {
			let jugador = this.state.ego;
			let len = this.state.piernas * 5 + this.state.escaleras * 6 + 3;
			let mesa = Array.from({ length: len }, () => -1);
			let index = -1;
			if (this.state.enMesa[jugador].length > 0) {
				mesa[0] = -2;
				index = 2;
			} else {
				index = 1;
			}
			let nuevaPlantilla = this.state.esquema.slice();
			nuevaPlantilla.forEach((carta, x) => {
				switch (carta) {
					case -2:
						mesa[index++] = -1;
						mesa[index++] = -2;
						mesa[index++] = -1;
						break;
					case -1:
						break;
					default:
						mesa[index++] = carta;
						nuevaPlantilla[x] = -1;
				}
			});
			let acum = 0;
			let i = 0;
			while (i < mesa.length) {
				if (mesa[i++] === -1) {
					if (++acum === 3) {
						mesa.splice(--i, 1);
						--acum;
					}
				} else {
					acum = 0;
				}
			}
			let j = 0;
			while (j < mesa.length - 2) {
				if (JSON.stringify(mesa.slice(j, j + 3)) === JSON.stringify([-2, -1, -1])) {
					mesa.splice(j, 3);
				} else {
					j++;
				}
			}
			if (mesa[mesa.length - 2] === -1) {
				mesa.pop();
			}
			let copiedEnMesa = this.state.enMesa.slice().map((row) => { return row.slice(); });
			copiedEnMesa[jugador] = copiedEnMesa[jugador].concat(mesa);
			let mano = this.state.enMano.slice();
			mano = mano.filter(c => c !== -1);
			let copiedCartasEnMano = this.state.cartasEnMano.slice();
			copiedCartasEnMano[jugador] = mano.length;
			let recienBajo = (this.state.enMesa[jugador].length === 0);
			let bajo = {
				indice: jugador,
				cartas: copiedEnMesa[jugador],
				enMano: mano
			}
			this.setState({
				recienBajo: recienBajo, enMesa: copiedEnMesa, enMano: mano, era: mano, cartasEnMano: copiedCartasEnMano, esquema: nuevaPlantilla
			});
			const requestOptions = {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify(bajo)
			};
			fetch('api/bajo', requestOptions);
		} else {
			let mensaje = 'No puede quedarse sin cartas en mano; lamentablemente, debe descartar una de las cartas que forman este juego para seguir.';
			this.setState({ mostrarError: true, mensaje: mensaje });
			this.cancelarPlantilla();
		}
	}

	cartaClick(e, whichOne) {
		e.preventDefault();
		if (this.state.ego === this.state.turno) {
			if (!this.state.levanto) {
				if (whichOne === 'MAZO' || this.state.enMesa[this.state.ego].length === 0) {
					let carta = (whichOne === 'POZO') ? this.state.pozo : this.state.mazo;
					let tempJugador = JSON.parse(JSON.stringify(this.state.jugador));
					tempJugador[this.state.ego].accion = 'LEVANTÓ DEL ' + whichOne;
					let tempEnMano = this.state.enMano.slice();
					tempEnMano.push(carta);
					let copiedCartasEnMano = this.state.cartasEnMano.slice();
					copiedCartasEnMano[this.state.ego] = tempEnMano.length;
					let pozo = (whichOne === 'POZO') ? this.state.anteriorPozo : this.state.pozo;
					let mostrarMazo = (whichOne === 'MAZO' && this.state.ultimaDelMazo) ? -1 : -3;
					this.setState({
						enMano: tempEnMano, era: tempEnMano, jugador: tempJugador, levanto: true, pozo: pozo,
						mostrarMazo: mostrarMazo, cartasEnMano: copiedCartasEnMano
					});
					const requestOptions = {
						method: 'POST',
						headers: { 'Content-Type': 'application/json' },
						body: JSON.stringify('levanto-' + whichOne)
					};
					fetch('api/levanto', requestOptions);
				} else {
					this.setState({ mostrarError: true, mensaje: 'Ya bajó cartas y no puede levantar más del pozo.' });
				}
			} else {
				this.setState({ mostrarError: true, mensaje: 'Ya levantó una carta y no puede levantar de nuevo hasta su próximo turno.' });
			}
		} else {
			if (this.state.jugador[this.state.turno].accion === 'LEVANTÓ DEL MAZO') {
				if (whichOne === 'POZO' && this.state.enMesa[this.state.ego].length === 0 && this.state.pozo !== -1) {
					fetch('api/robarPozo/' + this.state.ego)
						.then(response => response.json())
						.then(data => {
							if (data === "OK") {
								let tempJugador = JSON.parse(JSON.stringify(this.state.jugador));
								tempJugador[this.state.ego].accion = 'LEVANTÓ DEL POZO';
								let tempEnMano = this.state.enMano.slice();
								tempEnMano.push(this.state.pozo);
								let copiedCartasEnMano = this.state.cartasEnMano.slice();
								copiedCartasEnMano[this.state.ego] = tempEnMano.length;
								let pozo = this.state.anteriorPozo;
								this.setState({
									enMano: tempEnMano, era: tempEnMano, jugador: tempJugador, pozo: pozo, cartasEnMano: copiedCartasEnMano
								});
							}
						});
				} else {
					if (whichOne === 'POZO') {
						this.setState({ mostrarError: true, mensaje: 'No puede levantar del pozo.' });
					} else {
						this.setState({ mostrarError: true, mensaje: 'No es su turno todavía; espere para levantar del mazo.' });
					}
				}
			} else {
				let mensaje = '';
				if (this.state.jugador[this.state.turno].accion === 'LEVANTÓ DEL POZO') {
					mensaje = 'El jugador de turno levantó del pozo; usted no puede seguir levantando del pozo.';
				} else {
					mensaje = 'El jugador de turno todavía no levantó';
				}
				this.setState({ mostrarError: true, mensaje: mensaje });
			}
		}
	}

	reclamarPozo() {
		const requestOptions = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify(this.state.ego)
		};
		fetch('api/reclamar', requestOptions);
	}

	panicoClick() {
		fetch('api/tap/' + this.state.ego).then(res => res.json()).then(data => this.loadEstado(data));
	}

	salvar(contrasena) {
		const requestOptions = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({
				enMano: JSON.stringify(this.state.era),
				enMesa: JSON.stringify(this.state.enMesa[this.state.ego]),
				contrasena: contrasena,
				jugadorID: this.state.ego,
				partidaID: this.state.partidaID
			})
		}
		fetch('api/salvarJugador', requestOptions);
	}

	render() {
		if (this.state === null) return(<div></div>);
		const { enMano, esquema, cartasEnMano, mostrarError, mostrarCorto, mostrarFin, mostrarSalvar, jugador, 
			enMesa, pozo, mostrarMazo, turno, mensaje, queMano, piernas, escaleras, reclamarOn } = this.state;
		const enManoAll = (typeof this.state.enManoAll !== 'undefined') ? this.state.enManoAll : Array.from({ length: jugador.length }, () => []);
		const puntosEnMano = (typeof this.state.enManoAll !== 'undefined') ? this.state.puntosEnMano : [];
		const listaJugadores = jugador.map((jug, i) => {
			if (i !== this.state.ego) {
				return (
					<div key={i} style={{ marginBottom: 8 }}>
						<Jugador data={jug} enMano={cartasEnMano[i]} turno={i === turno} />
						<Mesa cartas={enMesa[i]} pDragEnter={this.dragEnter} pDragTarget={this.dragTarget} index={i} display={false} />
					</div>
				);
			} else {
				return (
					<div key={i} style={{ marginBottom: 8 }}>
						<Jugador data={jug} enMano={cartasEnMano[i]} turno={i === turno} />
						<Mano cartas={enMano} pDragStart={this.dragStart} pDragEnter={this.dragEnter} pDragEnd={this.dragEnd} pDragTarget={this.dragTarget} />
						<Plantilla esquema={esquema} pDragEnter={this.dragEnter} pDragTarget={this.dragTarget}
							pCancelar={this.cancelarPlantilla} pBajar={this.bajar} bajo={enMesa[i].length > 0} ref={this.plantilla} />
						<Mesa cartas={enMesa[i]} pDragEnter={this.dragEnter} pDragTarget={this.dragTarget} index={i} display={true} />
					</div>
				);
			}
		});
		return (
			<div>
				<FinPartida jugadores={jugador} show={mostrarFin} pClickFin={this.handleReset} />
				<div style={{ minHeight: '100vh', backgroundColor: '#d5d5d5' }}>
					<Cabecera pozo={pozo} mazo={mostrarMazo} queMano={queMano} piernas={piernas} escaleras={escaleras} pClick={this.cartaClick}
						pReclamar={this.reclamarPozo} reclamarOn={reclamarOn} pDragEnter={this.dragEnter} pDragTarget={this.dragTarget} 
						pPanico={this.panicoClick} />
					<div style={{ padding: 8 }}>
						<AvisoError show={mostrarError} mensaje={mensaje} cierreAviso={this.cancelarNotificacion} />
						<AvisoCorto show={mostrarCorto} quien={jugador[turno].nombre} jugador={jugador} ultimaMano={queMano === 8}
							puntosEnMano={puntosEnMano} enMano={enManoAll} ref={this.continuar} ego={this.state.ego} />
						<AvisoSalvar show={mostrarSalvar} pSalvar={this.salvar} />
						<div>
							{listaJugadores}
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default withRouter(Tablero);
