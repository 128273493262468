import React from 'react';
import { Chip } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import '../index.css';

const show = {
    display: 'block'
}

const hide = {
    display: 'none'
}

class Jugador extends React.Component {
    render () {
        let pumba = (this.props.enMano === 1) ? show : hide;
        let chipStyle = { width: 200, justifyContent: 'left', backgroundColor: (this.props.turno) ? '#65BE0D' : '' };
        return (
            <div className='contenedor mano' style={{ borderRadius: '5px 5px 0 0' }}>
                <Chip color='primary' size='small' icon={<AccountCircleIcon />} 
                    label={this.props.data.nombre} style={chipStyle} />
                <div className='puntos'>{this.props.data.puntos}</div>
                <div className='cuantasCartas' />
                <div className='cuantasCartasValor'>{this.props.enMano}</div>
                <div className='pumba' style={pumba}>PUMBA</div>
                {(this.props.data.accion !== '') ? <div className='accion'>{this.props.data.accion}</div> : ''}
            </div>
        );
    }
}

export default Jugador;