import React from 'react';
import '../index.css';

const palos = '♥♦♣♠';
const valores = 'A234567890JQK';
const otrosValores = ['JK',''];
const colores = ['roja','negra','joker','vacia','espacio','tapada'];

class Carta extends React.Component {
    render() {
        let codigo = parseInt(this.props.codigo);
        let subMazo = (codigo >= 0) ? Math.floor(codigo/54) : 0;
        let indicePalo = (codigo >= 0) ? Math.floor((codigo-subMazo*54)/13) : 4+codigo*(-2);
        let indiceValor = (codigo >= 0) ? codigo-subMazo*54-indicePalo*13 : -1;
        let palo = (indicePalo < 4) ? palos[indicePalo] : '';
        let valor = (indicePalo < 4) ? valores.substring(indiceValor,indiceValor+1) : otrosValores[Math.floor((indicePalo-4)/2)];
        if (indiceValor === 9) valor = '1' + valor;
        let indiceColor = Math.floor(indicePalo/2);
        let color = 'carta ' + colores[indiceColor];
        return (
            <div className={color}>
                {valor}
                <div className='palo'> {palo} </div>
            </div >
        )
    }
}

export default Carta;