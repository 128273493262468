import React from 'react';
import Carta from './Carta';
import '../index.css';

class Mesa extends React.Component {
    render() {
        const { cartas } = this.props;
        const height = (this.props.display) ? 86 : 65;
        return (
            <div className='contenedor' style={{ borderTop: 0, borderRadius: '0 0 5px 5px', minHeight: height }}>
                {(this.props.display) ? <p style={{ marginTop: 0, marginBottom: 8 }}>En mesa</p> : ''}
                <div className='mano'>
                    {(cartas.length !== 0) ? cartas.map((c, i) => {
                        if (c === -1) {
                            return (
                                <div className='unaCarta' key={i} draggable='false'
                                    onDragEnter={(e) => { this.props.pDragEnter(e, 'mesa'+this.props.index, i) }}
                                    onDragOver={(e) => { this.props.pDragTarget(e) }}
                                    onDrop={(e) => { this.props.pDragTarget(e) }}
                                >
                                    <Carta codigo={c} />
                                </div>
                            )
                        } else {
                            return (
                                <div className='unaCarta' key={i} draggable='false'>
                                    <Carta codigo={c} />
                                </div>
                            )
                        }
                    }) : ''}
                </div>
            </div>
        )
    }
}

export default Mesa;

