import React from 'react';
import { Button, IconButton } from '@mui/material';
import BackupIcon from '@mui/icons-material/Backup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import Carta from './Carta';

class Cabecera extends React.Component {

    salvarPartida() {
		fetch('api/salvar', { method: 'POST' });
    }
    
    render() {
        let comboP = (this.props.piernas === 0) ? '' : (this.props.piernas === 1) ? '1 pierna' : this.props.piernas + ' piernas';
        let comboE = (this.props.escaleras === 0) ? '' : (this.props.escaleras === 1) ? '1 escalera' : this.props.escaleras + ' escaleras';
        let mensaje = (comboP !== '' && comboE !== '') ? comboP + ' y ' + comboE : comboP + comboE;
        let pozo = this.props.pozo;
        let mazo = this.props.mazo;
        return (
            <div className='cabecera'>
                <img src='./Logo.jpg' height='80px' alt='Carioca logo'></img>
                <div className='mano' style={{ justifyContent: 'center' }}>
                    <div className='unaCarta' draggable='false' onClick={(e) => this.props.pClick(e, 'MAZO')}>
                        <Carta codigo={mazo} />
                    </div>
                    <div className='unaCarta' draggable='false' onClick={(e) => this.props.pClick(e, 'POZO')}
                        onDragEnter={(e) => { this.props.pDragEnter(e, 'pozo', 0) }}
                        onDragOver={(e) => { this.props.pDragTarget(e) }}
                        onDrop={(e) => { this.props.pDragTarget(e) }}>
                        <Carta codigo={pozo} />
                    </div>
                    <Button size='small' variant='contained' onClick={this.props.pReclamar} color='primary'
                        disabled={!this.props.reclamarOn}>Reclamar pozo</Button>
                </div>
                <div style={{ minWidth: 250 }}>
                    <span className='titulo'>Mano {this.props.queMano} de 8</span>
                    <br />
                    <span className='subtitulo'>({mensaje})</span>
                </div>
                <div style={{ justifyContent: 'flex-end', display: 'flex' }}>
                    <span onClick={this.salvarPartida}>
                        <IconButton aria-label='salvar estado de partida en la nube' >
                            <BackupIcon fontSize='large' style={{ color: '#ffffff' }} />
                            <span className='etiqueta'>Archivar partida</span>
                        </IconButton>
                    </span>
                    <span onClick={this.props.pPanico}>
                        <IconButton aria-label='restaurar estado de partida en caso de error'>
                            <FontAwesomeIcon icon={faSyncAlt} size='1x' style={{ color: '#ffffff', margin: 6 }} />
                            <span className='etiqueta'>Resolver error</span>
                        </IconButton>
                    </span>
                </div>
            </div>
        );
    }
}

export default Cabecera;