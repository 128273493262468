import React from 'react';
import './w3.css';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Chip } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Button } from '@mui/material';
import Carta from './Carta';

const display = {
    display: 'block'
};
const hide = {
    display: 'none'
};

class AvisoCorto extends React.Component {
    constructor(props) {
        super(props);
        this.state = { apagado: false };
        this.handleClick = this.handleClick.bind(this);
        this.prenderBoton = this.prenderBoton.bind(this);
        this.handleAbandonar = this.handleAbandonar.bind(this);
    }

    handleClick(e) {
        e.preventDefault();
        fetch('api/continuar', { method: 'POST' });
        this.setState({ apagado: true });
    }

    handleAbandonar(e) {
        e.preventDefault();
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(this.props.ego)
        };
        fetch('api/abandonar', requestOptions);
        this.setState({ apagado: true });
    }

    prenderBoton() {
        this.setState({ apagado: false });
    }

    render() {
        let showOrNot = (this.props.show) ? display : hide;
        let chipStyle = { width: 200, justifyContent: 'left' };
        let jugadores = this.props.jugador;
        let puntosEnMano = this.props.puntosEnMano;
        let enMano = this.props.enMano;
        let apagado = this.state.apagado;
        return (
            <div className='w3-modal' style={showOrNot}>
                <div className='w3-modal-content w3-animate-zoom w3-card-4'>
                    <div className='w3-container w3-green'>
                        <h4><FontAwesomeIcon icon={faInfoCircle} style={{ color: 'white', marginRight: 12 }} />
                            {this.props.quien} cortó. Estas cartas quedaron en mano:
                        </h4>
                    </div>
                    <div className='w3-container'>
                        <p></p>
                        {(jugadores.length !== 0) ? jugadores.map((j, i) => {
                            return (
                                <div key={i} style={{ marginBottom: 8 }}>
                                    <div className='contenedor mano' style={{ borderRadius: '5px 5px 0 0' }}>
                                        <Chip color='primary' size='small' icon={<AccountCircleIcon />} label={j.nombre} style={chipStyle} />
                                        <div className='puntos-en-mano'>Puntos en mano: {puntosEnMano[i]}</div>
                                        <div className='puntaje-actualizado'>PUNTAJE ACTUALIZADO: {j.puntos}</div>
                                    </div>
                                    <div className='contenedor mano' style={{ borderTop: 0, borderRadius: '0 0 5px 5px', minHeight: 65 }}>
                                        {(enMano[i].length !== 0) ? enMano[i].map((c, x) => {
                                            return (
                                                <div key={x} draggable={false}>
                                                    <Carta codigo={c} />
                                                </div>
                                            )
                                        }) : ''}
                                    </div>
                                </div>
                            )
                        }) : ''}
                        <p></p>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', padding: '0 16px 16px' }}>
                        <Button size='small' variant='contained' onClick={this.handleAbandonar} color='secondary' disabled={apagado || this.props.ultimaMano}>
                            Abandonar partida
                        </Button>
                        <Button size='small' variant='contained' onClick={this.handleClick} color='primary' disabled={apagado}>
                            Presione para continuar la partida
                        </Button>
                    </div>
                </div>
            </div>
        );
    }
}

export default AvisoCorto;