import React from 'react';
import { withRouter } from './withRouter';
import { Button, TextField, FormControl, InputLabel, Select, MenuItem } from '@mui/material';

const display = {
    display: 'block'
};

const hide = {
    display: 'none'
};

class Registrar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            nombre: '',
            contrasena: '',
            apagado: true,
            listo: false,
            listoNombre: false,
            esPrimero: false,
            esperando: [],
            errAddJugador: false,
            errorContrasena: false,
            errorContrasenaMsg: '',
            partida: -1,
            jugadores: [],
            jugador: -1,
            savedPartidas: []
        }
        this.capturarNombre = this.capturarNombre.bind(this);
        this.capturarContrasena = this.capturarContrasena.bind(this);
        this.entrar = this.entrar.bind(this);
        this.restaurar = this.restaurar.bind(this);
        this.handleLista = this.handleLista.bind(this);
        this.handleComenzar = this.handleComenzar.bind(this);
        this.resetear = this.resetear.bind(this);
        this.handleReset = this.handleReset.bind(this);
        this.selectPartida = this.selectPartida.bind(this);
        this.selectJugador = this.selectJugador.bind(this);
        this.handleNuevaPartida = this.handleNuevaPartida.bind(this);
        this.viewOnly = this.viewOnly.bind(this);
    }

    componentDidMount() {
        this.loadPartidas();
    }

    componentDidUpdate(prevProps) {
        if (this.props.eventSource !== null && this.props.eventSource !== prevProps.eventSource) {
            this.props.eventSource.addEventListener('lista', this.handleLista);
            this.props.eventSource.addEventListener('comenzar', this.handleComenzar);
            this.props.eventSource.addEventListener('reset', this.handleReset);
            this.props.eventSource.addEventListener('nueva', this.handleNuevaPartida);
        }
    }

    handleLista(event) {
        const message = JSON.parse(event.data);
        this.setState({ esperando: message });
        if (this.state.jugador === 0 && message.length === this.state.jugadores.length) {
            fetch('api/comenzarRestaurada', { method: 'POST' });
        }
    }

    handleComenzar(event) {
        this.props.eventSource.removeEventListener('reset', this.handleReset);
        const initialData = JSON.parse(event.data);
        this.props.pInitialData(initialData);
        this.props.navigate('/partida');
    }

    handleReset() {
        if (this.props.eventSource !== null) {
            this.props.eventSource.close();
        }
        window.location.reload();
    }

    handleNuevaPartida(event) {
        const par = JSON.parse(event.data);
        this.setState({ listo: true, partida: par, jugadores: par > -1 ? this.state.savedPartidas.filter(p => p.value === par)[0].jugadores : [] });
    }

    loadPartidas() {
        fetch('api/getPartidas/'+new Date().getTimezoneOffset()).then(res => res.json())
        .then(data => this.setState({ 
            listo: data.codigo > -2,
            partida: data.codigo > -1 ? data.codigo : -1,
            jugadores: data.codigo > -1 ? data.partidas.filter(par => par.partida.id === data.codigo)[0].jugadores : [],
            savedPartidas: data.partidas.length > 0 ? data.partidas.map(par => {
                return {
                    value: par.partida.id,
                    name: '[' + par.partida.dateTimeUTC.replace('T',' ') + '] Mano ' + (par.partida.rueda - 5) + ' de 8, ' + par.jugadores.length + ' jugadores',
                    jugadores: par.jugadores
                }
            }) : []
        }));
    }

    capturarNombre(event) {
        if (event.target.value !== '') {
            if (event.target.value.length < 51) {
                this.setState({ apagado: false, nombre: event.target.value });
            }
        } else {
            this.setState({ apagado: true, nombre: event.target.value });
        }
    }

    capturarContrasena(event) {
        if (event.target.value !== '') {
            if (event.target.value.length < 51) {
                this.setState({ apagado: false, contrasena: event.target.value });
            }
        } else {
            this.setState({ apagado: true, contrasena: event.target.value });
        }
    }

    entrar(e) {
        e.preventDefault();
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(this.state.nombre.toUpperCase())
        };
        fetch('api/addJugador', requestOptions)
            .then(response => response.json())
            .then(data => {
                if (data !== 'ERROR') {
                    this.props.pSubscripcion(data);
                    this.setState({ apagado: true, listo: true, listoNombre: true, esPrimero: (data === 0), errAddJugador: false })
                } else {
                    this.setState({ apagado: false, listo: false, listoNombre: false, esPrimero: false, errAddJugador: true })
                }
            });
    }

    restaurar(e) {
        e.preventDefault();
        if (this.state.contrasena === this.state.jugadores.filter(jug => jug.jugadorID === this.state.jugador)[0].contrasena) {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    partidaID: this.state.partida,
                    jugadorID: this.state.jugador
                })
            };
            fetch('api/restaurarJugador', requestOptions)
            .then(response => response.json())
            .then(data => {
                if (data !== 'ERROR') {
                    this.props.pSubscripcion(this.state.jugador);
                    this.setState({ apagado: true, listo: true, listoNombre: true, esPrimero: false, errorContrasena: false, errorContrasenaMsg: '', errAddJugador: false })
                } else {
                    this.setState({ apagado: false, listo: false, listoNombre: false, esPrimero: false, errAddJugador: true, errorContrasena: false, errorContrasenaMsg: '' })
                }
            });
        } else {
            this.setState({ errorContrasena: true, errorContrasenaMsg: 'Contraseña incorrecta' });
        }
    }

    selectJugador(event) {
        event.preventDefault();
        this.setState({ jugador: event.target.value });
    }

    selectPartida(event) {
        event.preventDefault();
        let id = event.target.value;
        if (id !== -1) {
            this.setState({ 
                jugadores: this.state.savedPartidas.filter(par => par.value === id)[0].jugadores,
                jugador: this.state.savedPartidas.filter(par => par.value === id)[0].jugadores[0].jugadorID
            });
        }
        this.setState({ partida: id });
    }

    viewOnly() {
        fetch('api/tap').then(res => res.json())
        .then(data => {
            this.props.pInitialData(data);
            this.props.navigate('/partida');
        });
    }

    comenzar(event) {
        event.preventDefault();
        fetch('api/comenzar', { method: 'POST' });
    }

    async resetear(event) {
        event.preventDefault();
        await fetch('api/resetearPartida', { method: 'POST' });
        this.handleReset();
    }

    render() {
        let mostrarPrimero = (this.state.esPrimero) ? display : hide;
        let mostrarNoPrimero = (this.state.listoNombre && !this.state.esPrimero) ? display : hide;
        let mostrarEnCurso = this.state.errAddJugador ? display : hide;
        let mostrarResetear = ((this.state.listo && !this.state.esPrimero) || this.state.errAddJugador) ? display : hide;
        let mostrarLista = (this.state.listo && this.state.esperando.length > 0) ? display : hide;
        let lista = this.state.esperando.map((jugador, i) => <p key={i} style={{ margin: '8px 0 0 0' }}>{jugador.nombre}</p>);
        let partidas = this.state.savedPartidas.length > 0 ? this.state.savedPartidas.map(par => {
            return (
                <MenuItem key={par.value} value={par.value}>{par.name}</MenuItem>
            )
        }) : [];
        let jugadores = (this.state.partida > -1 && this.state.jugadores.length > 0) ? 
            this.state.jugadores
            .filter(jug => this.state.esperando.findIndex(e => e.jugadorID !== this.state.jugador && e.jugadorID === jug.jugadorID) < 0)
            .map(jug => {
            return (
                <MenuItem key={jug.jugadorID} value={jug.jugadorID}>{jug.nombre}</MenuItem>
            )
        }) : [];
        return (
            <div style={{ minHeight: '100vh', backgroundColor: '#ffffff', display: 'flex' }}>
                <div className='contenido'>
                    <div className='minicabecera-container'>
                        <div className='minicabecera'>
                            <img src='./Logo.jpg' height='40px' alt='Carioca logo'></img>
                            <div style={{ margin: 'auto' }}>¡Sumate a la partida!</div>
                        </div>
                        <div className='minicabecera-abajo'>
                            <FormControl variant='outlined' disabled={this.state.listo} fullWidth>
                                <InputLabel id='partida-select-label'>Elegí una partida</InputLabel>
                                <Select labelId='partida-select-label' value={this.state.partida} onChange={this.selectPartida}
                                    label='Elegí una partida'>
                                    <MenuItem value={-1}><em>Nueva Partida</em></MenuItem>
                                    {partidas}
                                </Select>
                            </FormControl>
                            {(this.state.partida === -1) ?
                                <TextField variant='outlined' label='Ingresá tu nombre' value={this.state.nombre} onChange={this.capturarNombre} disabled={this.state.listoNombre} fullWidth />
                                :
                                <FormControl variant='outlined' disabled={this.state.listoNombre} fullWidth>
                                    <InputLabel id='jugador-select-label'>Elegí un jugador</InputLabel>
                                    <Select labelId='jugador-select-label' value={this.state.jugador} onChange={this.selectJugador} 
                                        label='Elegí un jugador'>
                                        {jugadores}
                                    </Select>
                                </FormControl>
                            }
                            {(this.state.partida > -1) ?
                                <TextField variant='outlined' label='Ingresá la contraseña' value={this.state.contrasena} 
                                    onChange={this.capturarContrasena} disabled={this.state.listoNombre} error={this.state.errorContrasena}
                                    helperText={this.state.errorContrasenaMsg} fullWidth /> : ''
                            }
                            {(this.state.partida === -1) ? 
                                <Button variant='contained' color='primary' type='button' onClick={this.entrar} fullWidth disabled={this.state.apagado}>Entrá a jugar</Button>
                                :
                                <Button variant='contained' color='primary' type='button' onClick={this.restaurar} fullWidth disabled={this.state.apagado}>Restaurá la partida</Button> 
                            }
                        </div>
                    </div>
                    <div className='anuncio' style={mostrarPrimero}>
                        ¡Felicitaciones! Usted es la primera jugadora o el primer jugador en ingresar, y por lo tanto podrá decidir cuándo comenzar la partida.
                        <Button variant='contained' color='primary' fullWidth onClick={this.comenzar} style={{ marginTop: 8 }}>Comenzar</Button>
                    </div>
                    <div className='anuncio' style={mostrarNoPrimero}>
                        Por favor, espere a que los demás jugadores se registren, o en caso de una Nueva Partida, que la primera jugadora o el primer jugador en registrarse decida cuándo comenzar.
                    </div>
                    <div className='anuncio' style={mostrarEnCurso}>
                        Otra jugadora u otro jugador ha decidido iniciar otra partida, alguien ya entró con este nombre, o en este momento hay una partida en curso; por favor, vuelva a intentar. Alternativamente, puede ingresar como observador.
                        <Button variant='contained' fullWidth onClick={this.viewOnly} style={{ marginTop: '8px' }}>Observar Partida</Button>
                    </div>
                    <div className='anuncio' style={mostrarResetear}>
                        <p style={{ margin: '0px' }}>&nbsp;</p>
                        <span style={{ color: '#f50057' }}>En caso de error, consulte con los otros jugadores y proceda a resetear la partida corriente.</span>
                        <Button variant='contained' color='secondary' fullWidth onClick={this.resetear} style={{ marginTop: '8px' }}>Resetear Partida</Button>
                    </div>
                    <div className='lista-jugadores' style={mostrarLista}>
                        Por el momento, están esperando:
                        <div className='listado'>
                            {lista}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

// export default withStyles(styles)(Registrar);
export default withRouter(Registrar);