import React from 'react';
import { Button } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMedal } from '@fortawesome/free-solid-svg-icons';
import Fireworks from 'fireworks-canvas';

const display = {
    display: 'block'
};
const hide = {
    display: 'none'
};

class FinPartida extends React.Component {
    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (this.props.show !== prevProps.show) {
            this.startFireworks();
        }
    }

    handleClick(e) {
        e.preventDefault();
        this.props.pClickFin();
    }

    startFireworks() {
        const container = document.getElementById('container');
        const options = {
            maxRockets: 9,
            rocketSpawnInterval: 150,
            numParticles: 100,
            explosionMinHeight: 0.3,
            explosionMaxHeight: 0.7,
            explosionChance: 0.08,
        };
        const fireworks = new Fireworks(container, options);
        fireworks.start();
    }

    render() {
        let showOrNot = (this.props.show) ? display : hide;
        let jugadores = this.props.jugadores;
        return (
            <div className='fin' style={showOrNot}>
                <div id='container' style={{ minHeight: 850 }}></div>
                <div className='dashboard'>
                    {(jugadores.length !== 0) ? jugadores.map((j, i) => {
                        let medalColor = 'medal-color-'.concat((i < 3) ? i : 3);
                        return (
                            <div className='resultado' key={i}>
                                <div className={medalColor}><FontAwesomeIcon icon={faMedal} /></div>
                                <div>{j.nombre}</div>
                                <div>{j.puntos + ' '}puntos</div>
                            </div>
                        )
                    }) : ''}
                    <br />
                    <Button variant='outlined' onClick={this.handleClick} style={{ width: '100%', color: '#ffffff', borderColor: '#ffffff' }}>
                        Terminar Partida
                    </Button>
                </div>
            </div>
        )
    }

}

export default FinPartida;