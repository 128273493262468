import React from 'react';
import './w3.css';
import { Button, TextField } from '@mui/material';
import BackupIcon from '@mui/icons-material/Backup';

const display = {
    display: 'block'
};
const hide = {
    display: 'none'
};

class AvisoSalvar extends React.Component {

    constructor(props) {
        super(props);
        this.state = { apagado: true, contrasena: '', listo: false };
        this.capturarContrasena = this.capturarContrasena.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    capturarContrasena(event) {
        if (event.target.value !== '') {
            if (event.target.value.length < 51) {
                this.setState({ apagado: false, contrasena: event.target.value });
            }
        } else {
            this.setState({ apagado: true });
        }
    }

    handleSubmit(event) {
        event.preventDefault();
        this.props.pSalvar(this.state.contrasena);
        this.setState({ apagado: true, listo: true });
    }

    render() {
        let showOrNot = (this.props.show) ? display : hide;
        return (
            <div className='w3-modal' style={showOrNot}>
                <div className='w3-modal-content w3-animate-zoom w3-card-4'>
                    <div className='w3-container w3-blue'>
                        <h4><BackupIcon fontSize='large' style={{ color: '#ffffff', marginRight: 12 }} />Archivar partida</h4>
                    </div>
                    <div className='w3-container' style={{ color: '#707070' }}>
                        <form className='minicabecera-abajo' onSubmit={this.handleSubmit}>
                            <TextField variant='outlined' label='Ingresá una contraseña para recuperar tu mano más tarde'
                                value={this.state.contrasena} onChange={this.capturarContrasena} disabled={this.state.listo} fullWidth />
                            <Button variant='contained' color='primary' type='submit' disabled={this.state.apagado} fullWidth >Archivá tu mano</Button>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

export default AvisoSalvar;